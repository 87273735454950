<template>
  <div class="auth-wrapper auth-v2">loading...</div>
</template>

<script>
import axios from "@axios"
import { VBTooltip } from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { getHomeRouteForLoggedInUser } from "@/auth/utils"
import errorResponse from "@/libs/axiosError"
import useJwt from "@/auth/jwt/useJwt"

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {},
  data() {
    return {}
  },
  created() {
    this.getAuthDetails()
  },
  methods: {
    async getAuthDetails() {
      const formData = {
        token: this.$route.params.id,
        provider: "linkedin",
      }
      axios
        .post(`/social-login`, formData)
        .then((res) => {
          useJwt.setToken(res.data?.accessToken)
          useJwt.setSocialToken(this.$route.params.id)
          useJwt.setUserData(res.data?.user?.userData)
          this.$router
            .replace(
              getHomeRouteForLoggedInUser(res.data?.user?.userData.role.code)
            )
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Welcome ${res.data?.user?.userData?.name}`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `You have successfully logged in as ${res.data?.user?.userData.role.name}. Now you can start to explore!`,
                },
              })
            })
        })
        .catch((error) => {
          errorResponse(error, this.$router)
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
// .brand_logo {
//   padding-bottom: -100px !important;
//   height: 50px;
// }
@media only screen and (max-width: 950px) {
  // .form {
  //   margin-top: -100px;
  // }
  // .register {
  //   margin-top: -100px;
  // }
  .logo_img {
    width: 120px !important;
    margin-left: -80px;
  }
}
</style>
